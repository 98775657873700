/**
 * Redirect the user to the page they were trying to access before they were redirected to the login page.
 */
const homePage = '/'
let afterLoginUrl = homePage;

export default function useRedirectAfterLogin() {
  return {
    setAfterLoginUrl: (url: string) => {
      if (!url.startsWith('/')) return; // cannot set a dud url
      if (url.includes('__cypress')) return; // cypress is so annoying sometimes
      if (!url.startsWith('/auth')) afterLoginUrl = url;
    },
    getAfterLoginUrl: () => {
      // clear after first use so we dont; get redirect loops etc
      const url = afterLoginUrl;
      afterLoginUrl = homePage;
      return url;
    }
  };
}
