import * as Sentry from "@sentry/vue";
import { Router } from 'vue-router';
import { Environment } from '@/service/Environment';
import type { App } from 'vue';

export default function enableSentry(app: App, router: Router) {
  Sentry.init({
    app,
    dsn: Environment.getSentryDSN(),
    integrations: [
      Sentry.browserTracingIntegration({router, instrumentNavigation: true}),
    ],
    enabled: !Environment.isUnitTest() && window.location.hostname !== 'localhost',
    tracePropagationTargets: [Environment.getAPI()],
    environment: Environment.getEnvironment(),
    release: Environment.getBuildNumber(),
    tracesSampleRate: 1.0,
    // want to strip out all but 1% of GET /screenings/:id/state but only one span is sent from here. Must be API side i guess
    beforeSendSpan(span) {
      // strip a lot of noise out of the trace
      if (span.op === 'measure' && span.description?.startsWith('intlify')) return null;
      if (span.op === 'resource.script') return null;
      return span;
    },
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.data?.url?.match(/\/screenings\/\d+\/state/)) {
        return null; // don't log the polling of the screening state endpoint
      }
      return breadcrumb;
    },
  });
}
