// s3 for file uploads
import { displayErrorMessage } from '@/service/api/interceptors/DisplayErrors';
import { S3FileUpload } from '@/api';
import { s3api } from '@/service/api/s3api';

async function uploadFile(s3: S3FileUpload, file: File): Promise<string | undefined> {
  // format the s3 upload and POST to amazon s3
  // see https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/modules/_aws_sdk_s3_presigned_post.html
  const formData = new FormData();
  Object.entries(s3.fields).forEach(([field, value]) => {
    formData.append(field, value); // authentication information
  });
  formData.append('file', file); // the actual file to upload
  s3api.post(s3.url, formData).catch(displayErrorMessage);
  // calculate the url where the file was uploaded to, so the caller can display the uploaded image
  return s3.url + (s3.fields.key ?? '');
}

const wait = (milliseconds: number) => new Promise((resolve) => setTimeout(resolve, milliseconds));

async function waitForFileToExist(url: string, attemptsLeft: number, sleepMilliseconds = 2000): Promise<string | undefined> {
  // poll to see if the file exists
  try {
    // if the file exists, return the url
    await s3api.head(url);
    return url;
  } catch (error) {
    // if at max retries, report the error
    if (attemptsLeft <= 0) {
      console.log(error);
      return undefined;
    }
    // otherwise try again
    await wait(sleepMilliseconds);
    return waitForFileToExist(url, attemptsLeft - 1); // recursive call
  }
}

// upload the file, and wait a few seconds for it to appear on S3
export async function s3uploadFileAndWait(parameters: S3FileUpload | undefined, file: File): Promise<string | undefined> {
  if (!parameters) return undefined;
  const s3url = await uploadFile(parameters, file);
  return s3url ? waitForFileToExist(s3url, 2) : undefined;
}

export function sanitiseFileName(unclean: string, length = 100): string {
  // Split the file name into base name and extension
  const parts = unclean.split('.');
  const extension = (parts.length > 1 ? '.' + parts.pop() : '').slice(0, 6); // max extension length
  const baseName = parts.join('.');

  // Sanitize the base name
  // must not have a / in it because it will be interpreted as a path element on the api
  const sanitizedBaseName = baseName.replace(/[^-. _a-zA-Z0-9]/g, ' ').trim().replace('  ', ' ').slice(-1 * length);

  // Recombine the sanitized base name and the extension
  return sanitizedBaseName + extension;
}
