<template>
  <v-app-bar elevation="1">
    <div class="d-flex align-center">
      <div>
        <router-link to="/">
          <img src="/logo-bhs.webp" alt="World Rugby Logo" class="px-3" style="height: 40px">
        </router-link>
      </div>
    </div>
    <template #append>
      <div class="d-none d-md-inline-block">
        {{ environment }}
      </div>
      <language-select class="ml-3" />
      <v-menu v-if="session.isLoggedIn" location="bottom">
        <template #activator="{ props }">
          <v-btn color="primary" v-bind="props" append-icon="mdi-dots-vertical" variant="outlined" class="ml-3" data-cy="my-account-menu">
            {{ session.userName }}
          </v-btn>
        </template>
        <v-list elevation="1">
          <v-list-item v-if="session.isImpersonating" :title="t('user.stopImpersonating')" data-cy="my-account-stop-impersonating-button" @click="stopImpersonating" />
          <v-list-item :title="t('about.title')" to="/about" data-cy="my-account-about-button" />
          <v-list-item :title="t('user.profile')" to="/account" data-cy="my-account-account-button" />
          <v-list-item :title="t('user.logout')" data-cy="logout-button" @click="onLogout" />
        </v-list>
      </v-menu>
      <template v-else>
        <div class="d-none d-md-inline-block">
          <v-btn-secondary to="/auth/player-signup" data-cy="player-signup-button" class="ml-3">
            {{ t('auth.signup.submit') }}
          </v-btn-secondary>
        </div>
        <v-btn-primary to="/auth/login" data-cy="login-button" class="mx-3">
          {{ t('auth.login.submit') }}
        </v-btn-primary>
      </template>
    </template>
  </v-app-bar>
</template>

<script setup lang="ts">
import { Environment } from '@/service/Environment';
import { computed } from 'vue';
import { useSession } from '@/compositions/UseSession';
import { useRouter } from 'vue-router';
import { api } from '@/service/api';
import LanguageSelect from '@/components/Widgets/LanguageSelect.vue';
import { useTranslation } from '@/compositions/UseTranslation';

defineProps<{ title?: string }>();
const environment = computed(() => Environment.isProduction() ? undefined : Environment.getEnvironment());
const session = useSession();
const router = useRouter();
const {t} = useTranslation();

async function stopImpersonating() {
  session.impersonate();
  await router.push('/');
}

const onLogout = async () => {
  await api.logout();
  session.logout();
  await router.push('/auth/login');
};

</script>
